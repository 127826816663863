.about-title-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
}

.about-title {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    object-fit: contain;
    padding-top: 40px;
}

.about-title > h1 {
    color: #fff;
    font-size: 90px;
    animation: slideUp ease 1s forwards 0s;
    opacity: 0;
    margin: 5vh 0 0 0;
}

.down-arrow {
	width: 60px;
	height: 60px;
    margin-top: 10px;
    cursor: pointer;
	border-radius: 50%;
    border: 3px solid #ffffff;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 16px;
		left: 19px;
		width: 20px;
		height: 20px;
		border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
		transform: rotate(-45deg);
	}
}

.about-section {
    padding: 2rem 10vw;
    text-align: left;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.9);
    background-size: cover;
}

.divider {
    border-bottom: 1px solid white;
}

.about-section-subtitle {
    font-size: 50px;
    color: #fff;
}

.about-section-text {
    font-size: 20px;
    margin: 20px 0;
    color: #fff;
}

.about-gallery {
    padding: 10px 10vw 0 10vw;
    text-align: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

.about-gallery-img {
    padding-top: 0px;
    max-height: 70vh;
    max-width: 100%;
    animation: fadeInAnimation 0.5s linear;
}

@media (max-width: 768px) {
    .about-gallery {
        padding: 10px 10px 0 10px;
        text-align: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    }

    .about-gallery-img {
        max-height: 100%;
        max-width: 100%;
    }
}

.about-carousel {
    padding: 10px 0 10px 0;
    object-fit: contain;
}

.about-sponsors {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.about-sponsor-item {
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}

.about-sponsor-text {
    margin-top: 10px;
    color: white;
}

@media screen and (max-width: 400px) {
    .about-title-img {
        overflow: visible;
        transform: translateX(30%);
    }

    .about-title > h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 1250px) {
    .about-title > h1 {
        font-size: 70px;
    }
}

@media screen and (max-width: 950px) {
    .about-title > h1 {
        font-size: 50px;
    }

    .about-sponsors {
        flex-direction: column;
    }
}