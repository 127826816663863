@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.about-title-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
}

.about-title {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    object-fit: contain;
    padding-top: 40px;
}

.about-title > h1 {
    color: #fff;
    font-size: 90px;
    -webkit-animation: slideUp ease 1s forwards 0s;
            animation: slideUp ease 1s forwards 0s;
    opacity: 0;
    margin: 5vh 0 0 0;
}

.down-arrow {
	width: 60px;
	height: 60px;
    margin-top: 10px;
    cursor: pointer;
	border-radius: 50%;
    border: 3px solid #ffffff;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 16px;
		left: 19px;
		width: 20px;
		height: 20px;
		border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
}

.about-section {
    padding: 2rem 10vw;
    text-align: left;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.9);
    background-size: cover;
}

.divider {
    border-bottom: 1px solid white;
}

.about-section-subtitle {
    font-size: 50px;
    color: #fff;
}

.about-section-text {
    font-size: 20px;
    margin: 20px 0;
    color: #fff;
}

.about-gallery {
    padding: 10px 10vw 0 10vw;
    text-align: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

.about-gallery-img {
    padding-top: 0px;
    max-height: 70vh;
    max-width: 100%;
    -webkit-animation: fadeInAnimation 0.5s linear;
            animation: fadeInAnimation 0.5s linear;
}

@media (max-width: 768px) {
    .about-gallery {
        padding: 10px 10px 0 10px;
        text-align: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    }

    .about-gallery-img {
        max-height: 100%;
        max-width: 100%;
    }
}

.about-carousel {
    padding: 10px 0 10px 0;
    object-fit: contain;
}

.about-sponsors {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.about-sponsor-item {
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}

.about-sponsor-text {
    margin-top: 10px;
    color: white;
}

@media screen and (max-width: 400px) {
    .about-title-img {
        overflow: visible;
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }

    .about-title > h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 1250px) {
    .about-title > h1 {
        font-size: 70px;
    }
}

@media screen and (max-width: 950px) {
    .about-title > h1 {
        font-size: 50px;
    }

    .about-sponsors {
        flex-direction: column;
    }
}
.navbar {
	background: rgb(255, 255, 255);
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 999;
	-webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.icon-logo {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    height: 80px;
    object-fit: contain;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(6, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 60vw;
	justify-content: end;
	margin-right: 2rem;
}

.nav-item {
	height: 70px;
}

.nav-links {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0rem 1rem;
	height: 100%;
	color: black;
	font-weight: 400;
}

.nav-links:hover {
    border-bottom: 6px solid rgb(27, 27, 27);
    background-color: rgba(232, 232, 232, 0.535);
    transition: all 0.2s ease-out;
	font-weight: 500;
}

.icon {
	height: 40px;
	width: 40px;
	object-fit: contain;
}

.social-media {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 4rem;
	padding-left: 20px;
	border-left: 1px solid #b7b7b7;
}

.icon-sm {
	height: 30px;
	width: 30px;
	object-fit: contain;
	padding: 0 10px;
	cursor: pointer;
}

@media (hover: hover) {
  .icon-sm:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: all 0.2s ease-in-out;
  }
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 60px;
      left: -120%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      background: #403d3d;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .icon-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }

    .nav-links {
      color: #fff;
    }

    .nav-links:hover {
      background-color: black;
      color: #242424;
      border-radius: 0;
    }

    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0px 0px 0px 20px ;
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 15px 20px 0px 0px ;
      font-size: 1.8rem;
      cursor: pointer;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    overflow: hidden;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    -webkit-animation: slideUp ease 0.3s forwards 0.3s;
            animation: slideUp ease 0.3s forwards 0.3s;
    opacity: 0;
    margin: 5vh;
}

.hero-container > p {
    color: #fff;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    -webkit-animation: slideUp ease .5s forwards 0.5s;
            animation: slideUp ease .5s forwards 0.5s;
    opacity: 0;
    margin: 1vh;
}

.hero-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hero-btns .btn {
    margin: 6px;
}

@-webkit-keyframes slideUp {
    0% {-webkit-transform: translateY(0);transform: translateY(0);}
    100% {-webkit-transform: translateY(-40px);transform: translateY(-40px);opacity:1;}
}

@keyframes slideUp {
    0% {-webkit-transform: translateY(0);transform: translateY(0);}
    100% {-webkit-transform: translateY(-40px);transform: translateY(-40px);opacity:1;}
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 4rem;
    }
    
    .hero-btns {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 2.5rem;
        padding-top: 50px;
    }

    .hero-container > p {
        font-size: 1.5rem;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .hero-btns {
        flex-direction: column;
    }
}

.scroll {
	width: 60px;
	height: 60px;
    margin-top: 10px;
    cursor: pointer;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
	20% {
		-webkit-transform: translateY(15px);
		        transform: translateY(15px);
	}
	40% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
	20% {
		-webkit-transform: translateY(15px);
		        transform: translateY(15px);
	}
	40% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
}
:root {
    --primary: #fff;
    --dark: #242424;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--dark {
    background-color: transparent;
    color: #242424;
    color: var(--dark);
    padding: 8px 20px;
    border: 2px solid #242424;
    border: 2px solid var(--dark);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #ffffffaa;
    color: #242424;
    transition: 250ms;
}

.btn--primary:hover {
    transition: all 0.3s ease-out;
    background: #ffffffaa;
    color: #242424;
    transition: 250ms;
}

.btn--dark:hover {
    color: #fff;
    background-color: #242424;
    background-color: var(--dark);
}
.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
    flex: 1 1;
    padding-left: 20px;
}

.footer-quote {
    color: #fff;
    margin-bottom: 16px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 2rem;
}

.footer-form {
    display: flex;
    flex-direction: row;
}

.footer-bottom {
    display: flex;
    width: 100%;
    flex-grow: 1;
    text-align: left;
}

.footer-bottom-right {
    text-align: right;
    padding-right: 20px;
}

.footer-author {
    text-decoration: underline;
    color:#fff;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .footer-input {
    width: 100%;
    }

    .btn {
    width: 100%;
    }

    .footer-bottom {
        flex-direction: column;
    }

    .footer-bottom-right {
        text-align: left;
        padding-right: 0;
    }

    .footer-quote {
        font-size: 24px;
        text-align: center;
    }
}
.cards__item {
    display: flex;
    flex: 1 1;
    margin: 1rem 1rem;
    border-radius: 10px;
    background-color: white;
    cursor: default;
}

.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    left: -40px;
    top: -16px;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    cursor: default;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
    border-radius: 0 10px 0 0;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    z-index: 0;
}

.cards__item__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.cards__item__info {
    padding: 0 15px 20px 15px;
    text-align: left;
    margin-top: -15px;
    cursor: default;
}

.cards__item__text {
    color: #252e48;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin: 0 0 15px 0;
    font-weight: 350;
}

.cards__item__padding {
    padding-left: 12px;
    padding-top: 3px;
}

@media screen and (max-width: 820px) {
    .cards__item__pic-wrap::after {
        font-size: 1rem;
    }
    
    .cards__item__text {
        font-size: 1rem;
    }
}
.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 110%;
    max-width: 1280px;
}

.cards__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 820px) {
    .cards__items {
        grid-template-columns: 1fr;
        padding-left: 0;
    }
}
.carousel-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-btn-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin: 10px 20px;
}

.carousel-btn {
    flex: 1 1;
}

.carousel-btns {
    display: flex;
    flex-direction: row;
}

@media (hover: hover) {
    .carousel-btn:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }
}

.carousel-img {
    width: 4rem;
    height: 4rem;
}

.carousel-slide {
    width: 80%;
}

.rotate180 {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

@media (max-width: 768px) {
    .carousel-slide {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .carousel-img {
        width: 2rem;
        height: 2rem;
    }
}

.carousel-sm-btn {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    justify-content: center;
    cursor: pointer;
    margin-top: 5px;
}

.carousel-sm-btn-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0px 10px;
    width: 100%;
    height: 100%;
}

.carousel-img-sm {
    height: 2rem;
}

.white-btn {
    -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
}
.testimonial-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    -webkit-animation: fadeInAnimation 0.5s linear;
            animation: fadeInAnimation 0.5s linear;
    min-height: 15rem;
}

.testimonial-quote {
    position: relative;
    top: -1.5rem;
}

.testimonial-img {
    width: 1.5rem;
    height: 1.5rem;
    margin: 20px;
}

.testimonial-content {
    margin: 20px;
    line-height: 1.5rem;
}

.testimonial-text {
    font-size: 1.3rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.758);
}

.testimonial-author {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
    }

    .testimonial-quote {
        position: relative;
        top: 0;
    }
}

@-webkit-keyframes fadeInAnimation {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}

@keyframes fadeInAnimation {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}
.video-wrapper {
    width: 100%; 
    height: auto;
}

.video-container {
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;
}

.video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.collapsible-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s max-height ease-in-out;
}

.collapsible-title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 5px;
    cursor: pointer;
}

.collapsible-title {
    font-size: 25px;
    font-weight: 600;
    margin: 20px;
}

.collapsible-default {
    margin-right: 10px;
    height: 40px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}

.collapsible-active {
    margin-right: 10px;
    height: 40px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    transition: ease-in-out 0.3s;
}

.collapsible-content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 20px;
    background-color: #ededed;
    border-radius: 0 0 10px 10px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .collapsible-title {
        font-size: 23px;
    }
    
    .collapsible-content {
        font-size: 20px;
    }
}
.classes-container {
    display: flex;
    flex-direction: column;
}

.classes-title-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 10;
}

.classes-title {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    object-fit: contain;
    padding-top: 40px;
}

.classes-title > h1 {
    color: #fff;
    font-size: 90px;
    -webkit-animation: slideUp ease 1s forwards 0s;
            animation: slideUp ease 1s forwards 0s;
    opacity: 0;
    margin: 5vh 0 0 0;
}

.classes-text {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10%;
    margin-right: 10%;
    white-space: pre-wrap;
}

.classes-text > div {
    width: 100%;
    text-align: left;
}

.classes-down-arrow {
	width: 60px;
	height: 60px;
    margin-top: 10px;
    cursor: pointer;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 16px;
		left: 19px;
		width: 20px;
		height: 20px;
		border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
	}
}

.classes-title-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.classes-btn {
    margin: 10px;
}

.classes-section {
    background-color: #fff;
    padding: 1.5rem 10vw 0 10vw;
}

.classes-section-grey {
    background-color: #ebebeb;
    padding: 1.5rem 10vw 0 10vw;
}

.classes-subtitle {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.classes-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 20px 0;
}

.classes-grid-item {
    transition: all 0.3s ease-in-out;
}

.classes-video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 20px 0;
}

.classes-section-special {
    background-color: #fff;
    width: 100%;
    text-align: left;
    background-image: url(/static/media/weddding.cebc9fec.jpg);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    background-size: cover;
    padding: 30px 0 30px 0;
}

.classes-section-special-2 {
    background-color: #fff;
    width: 100%;
    text-align: left;
    background-image: url(/static/media/mashaAmy.a48196ac.jpeg);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    background-size: cover;
    padding: 30px 0 30px 0;
}

.classes-subtitle-special {
    text-align: left;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    color: #fff;
    margin: 0 5vw;
}

.classes-text-special {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #fff;
    margin: 20px 5vw;
}

.classes-faq {
    margin: 20px 5vw;
}

.classes-contact-link {
    margin-right: 10px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .classes-title > h1 {
        font-size: 60px;
    }

    .classes-subtitle {
        font-size: 20px;
    }
    
    .classes-title-btns {
        flex-direction: column;
    }

    .classes-section {
        padding: 1rem 5vw;
    }

    .classes-section-grey {
        padding: 1rem 5vw;
    }

    .classes-grid-2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .classes-video-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .classes-subtitle-special {
        font-size: 30px;
    }
}
.home-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.home-container .home-section:nth-child(odd) {
    background-color: #f3f3f3;
}

.home-container .home-section:nth-child(even) {
    background-color: #fff;
}

.home-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 5vw;
}

.home-title {
    font-size: 35px;
    margin: 20px 0;
    font-weight: 350;
}

.home-text {
    font-size: 1.2rem;
    margin: 0 0 20px 0;
    font-weight: 350;
}

.home-video-wrapper {
    width: 60%; 
    height: auto;
}

.poster-img {
    max-width: 70%;
}

.gallery-img {
    max-width: 80%;
    max-height: 70vh;
}

@media (max-width: 768px) {
    .poster-img {
        max-width: 100%;
    }

    .gallery-img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media (max-width: 960px) {
    .home-video-wrapper {
        width: 90%; 
    }

    .home-title {
        font-size: 30px;
    }

    .home-section {
        padding: 20px 15px;
    }
}


@-webkit-keyframes fadeInAnimation {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}


@keyframes fadeInAnimation {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}


.contact-title-img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 80px;
}

.contact-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: calc(100vh - 80px);
    width: auto;
    display: flex;
    flex-direction: row;
    grid-row-gap: 2vw;
    row-gap: 2vw;
    justify-content: space-evenly;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    object-fit: contain;
    text-align: left;
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 5vh;
    opacity: 0;
    -webkit-animation: slideUp ease 1s forwards 0s;
            animation: slideUp ease 1s forwards 0s;
}

.contact-subsection {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 0 10px 0;
}

.margin-l {
    margin-left: 5vw;
    margin-right: 10vw;
}

.margin-r {
    margin-right: 5vw;
}

.contact-section > h1, .contact-subsection > h1 {
    color: #fff;
    font-size: 40px;
    margin: 5vh 0 20px 0;
}

.contact-input {
    padding: 10px 20px;
    border-radius: 2px;
    margin-top: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.contact-textarea {
    padding: 10px 20px;
    height: 25vh;
    border-radius: 2px;
    margin-top: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.contact-social {
    display: flex;
    flex-direction: row;
}

.contact-icon-sm {
	height: 30px;
	width: 30px;
	object-fit: contain;
	padding: 0 10px;
	cursor: pointer;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

.contact-buttons {
    display: flex;
    flex-direction: row;
    padding-bottom: 5vh;
    padding-top: 5vh;
    grid-gap: 20px;
    gap: 20px;
    justify-content: left;
}

@media screen and (max-width: 900px) {
    .contact-section > h1, .contact-subsection > h1 {
        font-size: 30px;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .contact-subsection {
        margin: 0;
    }

    .margin-l {
        margin: 0;
    }

    .margin-r {
        margin: 0;
    }

    .contact-buttons {
        flex-direction: column;
    }
}
