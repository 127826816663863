.home-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.home-container .home-section:nth-child(odd) {
    background-color: #f3f3f3;
}

.home-container .home-section:nth-child(even) {
    background-color: #fff;
}

.home-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 5vw;
}

.home-title {
    font-size: 35px;
    margin: 20px 0;
    font-weight: 350;
}

.home-text {
    font-size: 1.2rem;
    margin: 0 0 20px 0;
    font-weight: 350;
}

.home-video-wrapper {
    width: 60%; 
    height: auto;
}

.poster-img {
    max-width: 70%;
}

.gallery-img {
    max-width: 80%;
    max-height: 70vh;
}

@media (max-width: 768px) {
    .poster-img {
        max-width: 100%;
    }

    .gallery-img {
        max-width: 100%;
        max-height: 100%;
    }
}

@media (max-width: 960px) {
    .home-video-wrapper {
        width: 90%; 
    }

    .home-title {
        font-size: 30px;
    }

    .home-section {
        padding: 20px 15px;
    }
}


@keyframes fadeInAnimation {
    0% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
}

