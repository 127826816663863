.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
    flex: 1;
    padding-left: 20px;
}

.footer-quote {
    color: #fff;
    margin-bottom: 16px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 2rem;
}

.footer-form {
    display: flex;
    flex-direction: row;
}

.footer-bottom {
    display: flex;
    width: 100%;
    flex-grow: 1;
    text-align: left;
}

.footer-bottom-right {
    text-align: right;
    padding-right: 20px;
}

.footer-author {
    text-decoration: underline;
    color:#fff;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .footer-input {
    width: 100%;
    }

    .btn {
    width: 100%;
    }

    .footer-bottom {
        flex-direction: column;
    }

    .footer-bottom-right {
        text-align: left;
        padding-right: 0;
    }

    .footer-quote {
        font-size: 24px;
        text-align: center;
    }
}