.cards__item {
    display: flex;
    flex: 1;
    margin: 1rem 1rem;
    border-radius: 10px;
    background-color: white;
    cursor: default;
}

.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    left: -40px;
    top: -16px;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    cursor: default;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
    border-radius: 0 10px 0 0;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    z-index: 0;
}

.cards__item__img:hover {
    transform: scale(1.1);
}

.cards__item__info {
    padding: 0 15px 20px 15px;
    text-align: left;
    margin-top: -15px;
    cursor: default;
}

.cards__item__text {
    color: #252e48;
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin: 0 0 15px 0;
    font-weight: 350;
}

.cards__item__padding {
    padding-left: 12px;
    padding-top: 3px;
}

@media screen and (max-width: 820px) {
    .cards__item__pic-wrap::after {
        font-size: 1rem;
    }
    
    .cards__item__text {
        font-size: 1rem;
    }
}