.navbar {
	background: rgb(255, 255, 255);
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 999;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.icon-logo {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    height: 80px;
    object-fit: contain;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(6, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 60vw;
	justify-content: end;
	margin-right: 2rem;
}

.nav-item {
	height: 70px;
}

.nav-links {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0rem 1rem;
	height: 100%;
	color: black;
	font-weight: 400;
}

.nav-links:hover {
    border-bottom: 6px solid rgb(27, 27, 27);
    background-color: rgba(232, 232, 232, 0.535);
    transition: all 0.2s ease-out;
	font-weight: 500;
}

.icon {
	height: 40px;
	width: 40px;
	object-fit: contain;
}

.social-media {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 4rem;
	padding-left: 20px;
	border-left: 1px solid #b7b7b7;
}

.icon-sm {
	height: 30px;
	width: 30px;
	object-fit: contain;
	padding: 0 10px;
	cursor: pointer;
}

@media (hover: hover) {
  .icon-sm:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
  }
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 60px;
      left: -120%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      background: #403d3d;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }

    .icon-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }

    .nav-links {
      color: #fff;
    }

    .nav-links:hover {
      background-color: black;
      color: #242424;
      border-radius: 0;
    }

    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0px 0px 0px 20px ;
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 15px 20px 0px 0px ;
      font-size: 1.8rem;
      cursor: pointer;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
}