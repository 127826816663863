.contact-title-img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 80px;
}

.contact-container {
    height: fit-content;
    min-height: calc(100vh - 80px);
    width: auto;
    display: flex;
    flex-direction: row;
    row-gap: 2vw;
    justify-content: space-evenly;
    align-items: flex-start;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    object-fit: contain;
    text-align: left;
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 5vh;
    opacity: 0;
    animation: slideUp ease 1s forwards 0s;
}

.contact-subsection {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 0 10px 0;
}

.margin-l {
    margin-left: 5vw;
    margin-right: 10vw;
}

.margin-r {
    margin-right: 5vw;
}

.contact-section > h1, .contact-subsection > h1 {
    color: #fff;
    font-size: 40px;
    margin: 5vh 0 20px 0;
}

.contact-input {
    padding: 10px 20px;
    border-radius: 2px;
    margin-top: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.contact-textarea {
    padding: 10px 20px;
    height: 25vh;
    border-radius: 2px;
    margin-top: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.contact-social {
    display: flex;
    flex-direction: row;
}

.contact-icon-sm {
	height: 30px;
	width: 30px;
	object-fit: contain;
	padding: 0 10px;
	cursor: pointer;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

.contact-buttons {
    display: flex;
    flex-direction: row;
    padding-bottom: 5vh;
    padding-top: 5vh;
    gap: 20px;
    justify-content: left;
}

@media screen and (max-width: 900px) {
    .contact-section > h1, .contact-subsection > h1 {
        font-size: 30px;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .contact-subsection {
        margin: 0;
    }

    .margin-l {
        margin: 0;
    }

    .margin-r {
        margin: 0;
    }

    .contact-buttons {
        flex-direction: column;
    }
}