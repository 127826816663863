.collapsible-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.4s max-height ease-in-out;
}

.collapsible-title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-bottom: 5px;
    cursor: pointer;
}

.collapsible-title {
    font-size: 25px;
    font-weight: 600;
    margin: 20px;
}

.collapsible-default {
    margin-right: 10px;
    height: 40px;
    transform: rotate(270deg);
}

.collapsible-active {
    margin-right: 10px;
    height: 40px;
    transform: rotate(90deg);
    transition: ease-in-out 0.3s;
}

.collapsible-content {
    height: fit-content;
    font-size: 20px;
    background-color: #ededed;
    border-radius: 0 0 10px 10px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .collapsible-title {
        font-size: 23px;
    }
    
    .collapsible-content {
        font-size: 20px;
    }
}