video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    overflow: hidden;
}

.hero-container > h1 {
    color: #fff;
    font-size: 100px;
    animation: slideUp ease 0.3s forwards 0.3s;
    opacity: 0;
    margin: 5vh;
}

.hero-container > p {
    color: #fff;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    animation: slideUp ease .5s forwards 0.5s;
    opacity: 0;
    margin: 1vh;
}

.hero-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hero-btns .btn {
    margin: 6px;
}

@keyframes slideUp {
    0% {transform: translateY(0);}
    100% {transform: translateY(-40px);opacity:1;}
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 4rem;
    }
    
    .hero-btns {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 2.5rem;
        padding-top: 50px;
    }

    .hero-container > p {
        font-size: 1.5rem;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .hero-btns {
        flex-direction: column;
    }
}

.scroll {
	width: 60px;
	height: 60px;
    margin-top: 10px;
    cursor: pointer;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
		transform: rotate(-45deg);
	}
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}