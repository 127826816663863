.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 110%;
    max-width: 1280px;
}

.cards__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 820px) {
    .cards__items {
        grid-template-columns: 1fr;
        padding-left: 0;
    }
}