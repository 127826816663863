.classes-container {
    display: flex;
    flex-direction: column;
}

.classes-title-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 10;
}

.classes-title {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    object-fit: contain;
    padding-top: 40px;
}

.classes-title > h1 {
    color: #fff;
    font-size: 90px;
    animation: slideUp ease 1s forwards 0s;
    opacity: 0;
    margin: 5vh 0 0 0;
}

.classes-text {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10%;
    margin-right: 10%;
    white-space: pre-wrap;
}

.classes-text > div {
    width: 100%;
    text-align: left;
}

.classes-down-arrow {
	width: 60px;
	height: 60px;
    margin-top: 10px;
    cursor: pointer;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 16px;
		left: 19px;
		width: 20px;
		height: 20px;
		border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
		transform: rotate(-45deg);
	}
}

.classes-title-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.classes-btn {
    margin: 10px;
}

.classes-section {
    background-color: #fff;
    padding: 1.5rem 10vw 0 10vw;
}

.classes-section-grey {
    background-color: #ebebeb;
    padding: 1.5rem 10vw 0 10vw;
}

.classes-subtitle {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.classes-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 20px 0;
}

.classes-grid-item {
    transition: all 0.3s ease-in-out;
}

.classes-video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 20px 0;
}

.classes-section-special {
    background-color: #fff;
    width: 100%;
    text-align: left;
    background-image: url("../../asset/weddding.jpg");
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    background-size: cover;
    padding: 30px 0 30px 0;
}

.classes-section-special-2 {
    background-color: #fff;
    width: 100%;
    text-align: left;
    background-image: url("../../asset/mashaAmy.jpeg");
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    background-size: cover;
    padding: 30px 0 30px 0;
}

.classes-subtitle-special {
    text-align: left;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    color: #fff;
    margin: 0 5vw;
}

.classes-text-special {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #fff;
    margin: 20px 5vw;
}

.classes-faq {
    margin: 20px 5vw;
}

.classes-contact-link {
    margin-right: 10px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .classes-title > h1 {
        font-size: 60px;
    }

    .classes-subtitle {
        font-size: 20px;
    }
    
    .classes-title-btns {
        flex-direction: column;
    }

    .classes-section {
        padding: 1rem 5vw;
    }

    .classes-section-grey {
        padding: 1rem 5vw;
    }

    .classes-grid-2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .classes-video-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .classes-subtitle-special {
        font-size: 30px;
    }
}