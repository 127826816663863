.carousel-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-btn-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin: 10px 20px;
}

.carousel-btn {
    flex: 1;
}

.carousel-btns {
    display: flex;
    flex-direction: row;
}

@media (hover: hover) {
    .carousel-btn:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }
}

.carousel-img {
    width: 4rem;
    height: 4rem;
}

.carousel-slide {
    width: 80%;
}

.rotate180 {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

@media (max-width: 768px) {
    .carousel-slide {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .carousel-img {
        width: 2rem;
        height: 2rem;
    }
}

.carousel-sm-btn {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    justify-content: center;
    cursor: pointer;
    margin-top: 5px;
}

.carousel-sm-btn-wrapper {
    display: flex;
    flex-direction: row;
    margin: 0px 10px;
    width: 100%;
    height: 100%;
}

.carousel-img-sm {
    height: 2rem;
}

.white-btn {
    filter: grayscale(1) invert(1);
}