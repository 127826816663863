.video-wrapper {
    width: 100%; 
    height: auto;
}

.video-container {
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;
}

.video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}